import { useStaticQuery, graphql } from 'gatsby'

export const useColorTheme = () => {
  const { sanitySiteSettings } = useStaticQuery(
    graphql`
      query ColorPalette {
        sanitySiteSettings {
          backgroundColor {
            hex
          }
          textColor {
            hex
          }
          primaryColor {
            hex
          }
          secondaryColor {
            hex
          }
          accentColor {
            hex
          }
          navHoverColor {
            hex
          }
          navActiveColor {
            hex
          }
          footerColor {
            hex
          }
          footerTextColor {
            hex
          }
          footerNavHoverColor {
            hex
          }
          footerNavActiveColor {
            hex
          }
        }
      }
    `,
  )
  return sanitySiteSettings
}
