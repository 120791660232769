import { useColorTheme } from '../hooks/useColorTheme'

const BackgroundColor = () => {
  const { backgroundColor } = useColorTheme()
  return backgroundColor ? backgroundColor.hex : '#FFFFFF'
}

const TextColor = () => {
  const { textColor } = useColorTheme()
  return textColor ? textColor.hex : '#454963'
}

const PrimaryColor = () => {
  const { primaryColor } = useColorTheme()
  return primaryColor ? primaryColor.hex : '#022844'
}

const SecondaryColor = () => {
  const { secondaryColor } = useColorTheme()
  return secondaryColor ? secondaryColor.hex : '#FF859D'
}

const AccentColor = () => {
  const { accentColor } = useColorTheme()
  return accentColor ? accentColor.hex : '#FFD60A'
}

const NavHoverColor = () => {
  const { navHoverColor } = useColorTheme()
  return navHoverColor ? navHoverColor.hex : '#454963'
}

const NavActiveColor = () => {
  const { navActiveColor } = useColorTheme()
  return navActiveColor ? navActiveColor.hex : '#454963'
}

const FooterBackgroundColor = () => {
  const { footerColor } = useColorTheme()
  return footerColor ? footerColor.hex : '#FFFFFF'
}

const FooterTextColor = () => {
  const { footerTextColor } = useColorTheme()
  return footerTextColor ? footerTextColor.hex : '#454963'
}

const FooterNavHoverColor = () => {
  const { footerNavHoverColor } = useColorTheme()
  return footerNavHoverColor ? footerNavHoverColor.hex : '#454963'
}

const FooterNavActiveColor = () => {
  const { footerNavActiveColor } = useColorTheme()
  return footerNavActiveColor ? footerNavActiveColor.hex : '#454963'
}

const gray = `#828383`
const grayLight = `#F2F5F8`

const colors = {
  background: BackgroundColor,
  primary: PrimaryColor,
  secondary: SecondaryColor,
  accent: AccentColor,
  text: TextColor,
  heading: TextColor,
  muted: grayLight,
  mutedDark: gray,
  subheadingSecondary: gray,
  header: BackgroundColor,
  navHoverColor: NavHoverColor,
  navActiveColor: NavActiveColor,
  buttonBgPrimary: PrimaryColor,
  footerBackground: FooterBackgroundColor,
  footerTextColor: FooterTextColor,
  footerNavHoverColor: FooterNavHoverColor,
  footerNavActiveColor: FooterNavActiveColor,
  // Note: 'light' and 'dark' mode are the same as there is no 'dark' mode for this app
  // This is necessary for users that have enabled 'dark' mode on their machine
  modes: {
    dark: {
      text: TextColor,
      background: BackgroundColor,
      primary: PrimaryColor,
    },
  },
}

export default colors
