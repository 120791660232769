import colors from './colors'
import styles from './styles'
import '../styles/typography.css'

const index = {
  useCustomProperties: true,
  initialColorMode: `light`,
  colors,
  useLocalStorage: true,
  styles,
  breakpoints: ['35rem', '47rem', '69rem', '94rem'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: {
    blogPostContainer: 700,
    defaultContentContainer: 1024,
    defaultContainer: 1360,
    testimonialContainer: 1860,
  },
  fonts: {
    body: '"Aeonik-Regular", "Source Sans Pro", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif',
    // heading:
    //   '-apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif',
    // body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    // heading: 'inherit',
    heading:
      '"Aeonik-Bold", "Source Sans Pro", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif',
    monospace:
      'Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace',
  },
  fontSizes: [12, 14, 16, 18, 20, 24, 32, 48, 64, 72, 84],
  fontWeights: {
    body: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    heading: 700,
  },
  lineHeights: {
    heading: 1.35,
    body: 1.5,
    relaxed: 1.625,
    loose: 2.25,
  },
  letterSpacings: {
    body: `normal`,
    nav: `0.05em`,
    caps: `0.1em`,
    heading: `normal`,
  },
  text: {
    logotype: {
      fontFamily: 'heading',
      // fontSize: ['7', '7', '7', '7'],
      fontWeight: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'nav',
      color: 'primary',
      textTransform: 'none',
      textDecoration: 'none',
      mt: 1,
      mb: 0,
      py: ['3', '3', '3', '3'],
      px: ['0', '0', '0', '0'],
      '&:hover': {
        color: 'navHoverColor',
      },
    },
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      letterSpacing: 'heading',
      mt: 4,
      mb: 2,
      mx: 0,
    },
    headingHero: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'body',
      letterSpacing: 'heading',
      // mt: 4,
      // mb: 2,
      my: 2,
      mx: 0,
      fontSize: '128px',
    },
    paragraph: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      letterSpacing: 'body',
    },
    categoryTag: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'bold',
      letterSpacing: 'body',
      color: 'secondary',
      px: 2,
    },
    stageTag: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'bold',
      letterSpacing: 'body',
      color: 'secondary',
    },
    subheadingTwoColCta: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'semibold',
      letterSpacing: 'body',
      fontSize: ['5', '5', '5', '5'],
      mt: 4,
      mb: 0,
    },
  },
  layout: {
    containerHeader: {
      position: 'relative',
      maxWidth: 'defaultContainer',
      contain: 'content',
      mx: 'auto',
      px: ['3', '3', '3', '3'],
      bg: 'inherit',
    },
    containerPage: {
      maxWidth: 'defaultContainer',
      contain: 'content',
      mx: 'auto',
      p: ['3', '3', '3', '3'],
      bg: 'inherit',
    },
    containerBlog: {
      maxWidth: 'defaultContainer',
      contain: 'content',
      mx: 'auto',
      px: ['3', '4', '5', '5'],
      bg: 'inherit',
    },
    containerGarden: {
      maxWidth: 'defaultContainer',
      contain: 'content',
      mx: 'auto',
      px: ['3', '4', '5', '5'],
      bg: 'inherit',
    },
    containerHeroSection: {
      maxWidth: 'defaultContainer',
      contain: 'content',
      mx: 'auto',
      // py: [3, 4, 5, 6],
      px: ['3', '3', '3', '3'],
      bg: 'inherit',
    },
    containerBlogPost: {
      maxWidth: 'blogPostContainer',
      alignItems: 'center',
      contain: 'content',
      mx: 'auto',
      p: ['4', '4', '4', '4'],
      bg: 'inherit',
    },
    containerCTASection: {
      maxWidth: 'defaultContainer',
      alignItems: 'center',
      contain: 'content',
      // py: ['6', '6', '6', '6'],
      px: ['3', '3', '4', '6'],
      mx: 'auto',
      bg: 'inherit',
    },
    containerCardSection: {
      maxWidth: 'defaultContainer',
      alignItems: 'center',
      contain: 'content',
      // py: ['6', '6', '6', '6'],
      px: ['3', '3', '4', '4'],
      mx: 'auto',
      bg: 'inherit',
    },
    containerCTATwoColumnSection: {
      maxWidth: 'defaultContainer',
      alignItems: 'center',
      contain: 'content',
      pt: ['4', '4', '4', '4'],
      pb: ['4', '4', '4', '4'],
      px: ['3', '3', '3', '3'],
      mx: 'auto',
      bg: 'inherit',
    },
    containerCalendlyEmbed: {
      maxWidth: 'defaultContentContainer',
      contain: 'content',
      mx: 'auto',
      p: ['0', '3', '3', '3'],
      bg: 'inherit',
    },
    containerFeaturedTestimonialSection: {
      maxWidth: 'testimonialContainer',
      alignItems: 'center',
      contain: 'content',
      py: ['5', '5', '6', '6'],
      // px: ['3', '3', '2', '6'],
      mx: 'auto',
      bg: 'inherit',
    },
    containerTestimonialSection: {
      maxWidth: 'testimonialContainer',
      alignItems: 'center',
      contain: 'content',
      // py: ['5', '5', '6', '6'],
      px: ['3', '3', '5', '6'],
      mx: 'auto',
      bg: 'inherit',
    },
    containerTestimonialHeader: {
      maxWidth: 'defaultContainer',
      alignItems: 'center',
      contain: 'content',
      // py: ['6', '6', '6', '6'],
      px: ['3', '3', '5', '6'],
      mx: 'auto',
      bg: 'inherit',
    },
    header: {
      position: 'sticky',
      top: '0',
      padding: 0,
      zIndex: 99,
    },
    containerFooter: {
      maxWidth: 'defaultContainer',
      contain: 'content',
      mx: 'auto',
      p: ['3', '3', '3', '3'],
      bg: 'inherit',
    },
    carouselNavButton: {
      mx: [2, 2, 2, 2],
      padding: 0,
      '&:focus': {
        outline: `none`,
      },
      '&:focus-visible': {
        outline: `3px solid ${colors.primary}`,
      },
    },
    contactFormField: {
      my: [3, 3, 3, 3],
      mx: [3, 3, 3, 3],
      px: [0, 0, 0, 0],
      flexDirection: 'column',
    },
  },
  links: {
    logotype: {
      position: 'relative',
      textDecoration: 'none',
      mt: 1,
    },
    nav: {
      position: 'relative',
      fontFamily: 'body',
      fontSize: ['4', '4', '4', '4'],
      fontWeight: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'nav',
      textTransform: 'none',
      textDecoration: 'none',
      borderRadius: '1px',
      p: ['3', '3', '3', '3'],
      // color: 'text',
      '&:hover': {
        color: 'navHoverColor',
      },
    },
    navMobile: {
      position: 'relative',
      fontFamily: 'body',
      fontSize: ['4', '4', '4', '4'],
      fontWeight: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'nav',
      textTransform: 'none',
      textDecoration: 'none',
      borderRadius: '1px',
      p: ['2', '2', '2', '2'],
      // color: 'text',
      '&:hover': {
        color: 'navHoverColor',
      },
    },
    navFooter: {
      position: 'relative',
      fontFamily: 'body',
      fontSize: ['3', '3', '3', '3'],
      fontWeight: 'semibold',
      lineHeight: 'normal',
      letterSpacing: 'nav',
      textTransform: 'none',
      textDecoration: 'none',
      borderRadius: '1px',
      // p: ['2', '2', '2', '2'],
      color: 'inherit',
      '&:hover': {
        color: 'footerNavHoverColor',
      },
    },
    default: {
      color: 'primary',
      fontSize: [5, 5, 5, 5],
      '&:hover': {
        color: 'secondary',
        opacity: '0.65',
      },
    },
    noUnderline: {
      color: 'primary',
      textDecoration: 'none',
      '&:hover': {
        color: 'primary',
        opacity: '0.65',
      },
    },
    post: {
      color: 'primary',
      fontSize: [6, 6, 6, 6],
      '&:hover': {
        color: 'secondary',
        opacity: '0.65',
      },
    },
  },
  buttons: {
    primary: {
      color: 'colors.buttonLabelPrimary',
      bg: colors.buttonBgPrimary,
      border: `1px solid ${colors.buttonBgPrimary}`,
      // '&:hover': {
      //   bg: 'background',
      //   color: 'primary',
      // },
    },
    tag: {
      color: colors.background,
      bg: colors.secondary,
      border: `1px solid ${colors.secondary}`,
    },
  },
}

export default index
